import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

interface Props {
    pageName?: string
}

const ApplicationHeadContent: React.FC<Props> = ({pageName}) => (
    <StaticQuery
      query = { graphql `
        query {
            site {
                siteMetadata {
                    title
                }
            }
       }`
      }
      render = { data => (
            <Helmet>
                <title>
                    {(pageName ? pageName + ' | ' : '') + data.site.siteMetadata.title}
                </title>
                <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"></link>
            </Helmet>
      )}
   />
)

export default ApplicationHeadContent