// extracted by mini-css-extract-plugin
export const transitionFromTop = "navbar-module--transition-from-top--1ppqK";
export const burgerNavContainer = "navbar-module--burger-nav-container--1kOEY";
export const navbar = "navbar-module--navbar--ajLW5";
export const container = "navbar-module--container--XfbCh";
export const logo = "navbar-module--logo--1Yi_A";
export const navlinks = "navbar-module--navlinks--3yJYw";
export const navlinkItem = "navbar-module--navlink-item--i6jOq";
export const burgerIconContainer = "navbar-module--burger-icon-container--1-4o2";
export const stripe1 = "navbar-module--stripe1--3b8W1";
export const stripe2 = "navbar-module--stripe2--1jbmq";
export const stripe3 = "navbar-module--stripe3--1lBMw";
export const toggle = "navbar-module--toggle--29I0q";
export const navlinkBurger = "navbar-module--navlink-burger--1xzK-";
export const sublinksNav = "navbar-module--sublinks-nav--1BTlj";
export const subNavlinksContainer = "navbar-module--sub-navlinks-container--KMDJf";
export const burgerNav = "navbar-module--burger-nav--stAD3";
export const burgerNavSlideInOut = "navbar-module--burger-nav-slide-in-out--1bFHi";