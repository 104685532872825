import React from 'react'
import * as styles from './socialicons-list.module.scss'
import twitterIcon from '../../../static/social-media-icons/twitter_circle.svg'
import facebookIcon from '../../../static/social-media-icons/facebook_circle.svg'
import instagramIcon from '../../../static/social-media-icons/instagram_circle.svg'
import linkedinIcon from '../../../static/social-media-icons/linkedin_circle.svg'

enum SocialMediaType {
    twitter,
    facebook,
    linkedin,
    instagram
}

interface Props {
    vertical?: boolean
}

const SocialIcon = ( props: { type: SocialMediaType }) => {
    var url: string
    var title: string
    var icon: any

    switch(props.type) {
        case SocialMediaType.twitter: 
            url = 'https://twitter.com/addlevel'
            title = 'Twitter'
            icon = twitterIcon
        break

        case SocialMediaType.facebook: 
            url = 'https://www.facebook.com/addlevel'
            title= 'Facebook'
            icon = facebookIcon
        break

        case SocialMediaType.linkedin: 
            url = 'https://www.linkedin.com/company/addlevel-ab'
            title= 'LinkedIn'
            icon = linkedinIcon
        break

        case SocialMediaType.instagram: 
            url = 'https://www.instagram.com/addlevelab'
            title= 'Instagram'
            icon = instagramIcon
        break
    }

    return(
        <li className={styles.iconListItem}>
            <a href={url} target="_blank" title={'Follow us on ' + title}>
                <img className={styles.buttonIcon} src={icon} alt={'Follow us on ' + title} />
            </a>
        </li>
    )
}

const SocialIconsList: React.FC<Props> = ({ vertical }) => {
    return (
        <div className={styles.container}>
            <ul className={`${styles.socialMediaIconList} ${(vertical && styles.alignVertical)}`}>
                <SocialIcon type={SocialMediaType.twitter} />
                <SocialIcon type={SocialMediaType.facebook} />
                <SocialIcon type={SocialMediaType.instagram} />
                <SocialIcon type={SocialMediaType.linkedin} />
            </ul>
        </div>
    )
}

export default SocialIconsList