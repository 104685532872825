import React from 'react'
import * as styles from './layout.module.scss'
import Navbar from './navbar'
import Footer from './footer'
import ApplicationHeadContent from '../applicationHeadContent'

interface Props {
    pageName?: string
    hero?: React.ReactNode
}

const Layout: React.FC<Props> = ({children, pageName, hero}) => {
    return (
        <div>
            <ApplicationHeadContent pageName={pageName} />
            <Navbar />
            { hero }
            <div className={styles.container}>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout
