import React from 'react'
import * as styles from './footer.module.scss'
import SocialIconsList from './socialicons-list'

interface Props {

}

const Footer: React.FC<Props> = () => {
    return(
        <footer className={styles.footer}>
            <div className={styles.container}>
                <div className={styles.footerColumn}>
                    <p>
                    Contact information:
                    </p>
                    <div className={styles.addressContainer}>
                        <p>Addlevel</p>
                        <p>Odengatan 81</p>
                        <p>123 45 Stockholm</p>
                        <p>Sweden</p>
                    </div>
                    <p>All rights reserved. ©2021 Addlevel AB</p>
                    <p><span>addlevel</span> is a <span>TRUESEC</span> and Wintel Group company</p>
                </div>
                <div className={styles.footerColumn}>
                    <p>+46 (0)8 10 40 00</p>
                    <p>info@addlevel.se</p>
                    <SocialIconsList />
                </div>
            </div>   
        </footer>
    )
}

export default Footer