import React, { useState } from 'react'
import { Link } from 'gatsby'
import * as styles from './navbar.module.scss'
//import './navbar.module.scss'
import addlevelLogo from '../../../static/logos/addlevel-black.svg'

interface Props {

}

const Navbar: React.FC<Props> = () => {

    const [showSubNav, setShowSubNav] = useState<Boolean>(false)
    const [showBurgerNav, setShowBurgerNav] = useState<Boolean>(false)

    const handleSubNavClick = () => {
        setShowSubNav(!showSubNav)
    }

    const openSubNav = () => {
        setShowSubNav(true)
    }

    const closeSubNav = () => {
        setShowSubNav(false)
    }

    const handleBurgerNav = () => {
        setShowBurgerNav(!showBurgerNav)
    }

    const NavLink = (props: {to: string, text: string}) => (
        <div className={styles.navlinkItem} onClick={()=> closeSubNav()}>
            <Link to={props.to}>{props.text}</Link>
        </div>
    )

    const NavLinkWithSublinks = (props: {text: string}) => (
        <div className={styles.navlinkItem} 
            onClick={() => handleSubNavClick()}>
            <p>{props.text}</p>
        </div>
    )

    return (
        <React.Fragment>
            <nav className={styles.navbar}>
                <div className={styles.container}>
                    <div className={styles.logo}>
                        <Link to='/'><img src={addlevelLogo} alt="addlevel logo"/></Link>
                    </div>                  
                </div>  
            </nav>
        </React.Fragment>
    )
}

export default Navbar